import React from "react"
import "../styles.css"

export default function Footer(props) {
    return (
        <footer className="footer--container">
            <a href="mailto:codeThinh@gmail.com"><h1>codeThinh@gmail.com</h1></a>
        </footer>

    )
}